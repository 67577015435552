import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import styles from '../Step3.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { RadioGroup } from '@material-ui/core';
import { batch } from 'react-redux';
import NavigationBtnControl from '../../NavigationBtnControl/NavigationBtnControl';
import {
  popStep,
  pushStep,
  resetBookingForm,
  STEPS,
  updateStep3,
} from 'redux/bookingFormSlice';
import { formatDobForBE } from '../../utils/helperFunction';
import { resetSysType } from 'redux/authSlice';
import PatientCard from '../PatientCard/PatientCard';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import NewPatientCard from '../NewPatientCard/NewPatientCard';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import DetailDescriptionSection from '../../DetailDescriptionSection/DetaiDescriptionSection';

interface ReviewProps {
  onClose: () => void;
  bookingSummaryInfo: {
    avatar: string;
    title: string;
    serviceTitle: string;
    clinicName: string;
    clinicAddress: string;
    clinicEmail: string;
    clinicPhoneNumber: string;
  };
}

export interface NewPatient {
  phoneNumber: string;
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const PATIENT_LIST_TYPE = {
  NORMAL: 'normal-patient-list', // patient is allowed to book next appts
  INCOMPLETE_FUTURE_APPT: 'incomplete-future-appt', // patient who has future appts and is not allowed to book next appts
  INCOMPLETE_PAST_APPT: 'incomplete-past-appt', // patient who has appts in the past and is not allowed to book next appts
  SAME_TIME_APPT: 'same-time-appt', // patient who has appts at the same time and is not allowed to book next appts
};

export const NULL_PATIENT_ID = 'patient-id-null';

const Review: FC<ReviewProps> = ({ onClose, bookingSummaryInfo }) => {
  const {
    bookingFormSlice: {
      step1,
      step2: { newPatient, signUp },
      step3: { patients, chosenPatientId },
      pageStack,
    },
    authSlice: { isLoggedIn },
  } = useAppSelector((state) => state);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const dispatch = useAppDispatch();

  const previousPage = pageStack[pageStack.length - 2];

  const patient = useMemo(() => {
    return previousPage === STEPS.NEW_PATIENT
      ? { ...step1, ...newPatient, id: '' }
      : { ...signUp, id: '' };
  }, [newPatient, previousPage, signUp, step1]);

  const handleOnBackClick = () => {
    if (isLoggedIn) {
      batch(() => {
        dispatch(resetBookingForm());
        dispatch(resetSysType());
      });
      return onClose();
    }
    dispatch(popStep());
  };

  const handleNotYouClick = () => {
    dispatch(pushStep(STEPS.NEW_PATIENT));
  };

  const normalizedPatientInfo = () => {
    let chosenPatient = null;

    if (chosenPatientId === NULL_PATIENT_ID) {
      chosenPatient = patients.find((patient: any) => patient.id === null);
    } else {
      chosenPatient = patients.find(
        (patient: any) => patient.id === chosenPatientId
      );
    }

    // Detect if a new input patient is the same as the list of patients
    const duplicateNewPatient =
      previousPage === STEPS.NEW_PATIENT &&
      patients.find(
        (patientItem) =>
          patientItem.email?.toLowerCase() === patient.email?.toLowerCase() &&
          patientItem.firstName?.toLowerCase() ===
            patient.firstName?.toLowerCase() &&
          patientItem.lastName?.toLowerCase() ===
            patient.lastName?.toLowerCase()
      );

    const patientInfo =
      chosenPatient && previousPage !== STEPS.NEW_PATIENT
        ? {
            id: chosenPatient.id,
            email: chosenPatient.email,
            firstName: chosenPatient.firstName,
            lastName: chosenPatient.lastName,
            dob: chosenPatient.dob,
            phoneNumber: chosenPatient.phoneNumber,
            isNewPatient: chosenPatient.isNewPatient,
          }
        : {
            email: patient.email,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dob: formatDobForBE(patient.dob),
            phoneNumber: patient.phoneNumber,
            isNewPatient: true,
            ...(duplicateNewPatient && { id: duplicateNewPatient.id }),
          };

    return patientInfo;
  };

  const handleOnSubmit = async () => {
    const patientInfo = normalizedPatientInfo();

    batch(() => {
      dispatch(updateStep3({ patientInfo: { ...patientInfo, note: '' } }));
      dispatch(pushStep(STEPS.NOTE));
    });
  };

  const renderNotYouSection = () => {
    if (previousPage !== STEPS.PATIENT_INFO) {
      return null;
    }

    return (
      <div className={styles['not-you-section']}>
        not you?
        <button className={styles['click-here']} onClick={handleNotYouClick}>
          click here
        </button>
      </div>
    );
  };

  const renderDetailSection = (type: string) => {
    if (
      type === PATIENT_LIST_TYPE.NORMAL &&
      previousPage !== STEPS.PATIENT_INFO
    ) {
      return null;
    }

    return (
      <DetailDescriptionSection
        type={type}
        patients={patients}
        clinicInfo={{
          name: bookingSummaryInfo.clinicName,
          phoneNumber: bookingSummaryInfo.clinicPhoneNumber,
          email: bookingSummaryInfo.clinicEmail,
        }}
      />
    );
  };

  // Patients who are allowed to book next appts
  const renderPatients = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    if (bookablePatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.NORMAL)}
          {renderNotYouSection()}
        </div>

        <RadioGroup
          className={styles['card-container']}
          value={chosenPatientId || NULL_PATIENT_ID}
          onChange={(e) =>
            dispatch(updateStep3({ chosenPatientId: e.target.value }))
          }
        >
          {bookablePatients.map((patient) => (
            <PatientCard
              key={patient.id}
              isMultipleResult={patients.length > 1}
              patient={patient}
              chosenPatientId={chosenPatientId}
            />
          ))}
        </RadioGroup>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderPatientsHasFutureApptInFuture = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);
    const newPatients = patients.filter(
      (item) =>
        item.isBookable === false && item.appointment?.isInPast === false
    );

    if (newPatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.INCOMPLETE_FUTURE_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {newPatients.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderPatientsHasIncompleteApptInPast = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    const newPatients = patients.filter(
      (item) => item.isBookable === false && item.appointment?.isInPast === true
    );

    if (newPatients.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.INCOMPLETE_PAST_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {newPatients.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  const renderPatientWithApptAtTheSameTime = () => {
    const bookablePatients = patients.filter((item) => item.isBookable);

    const patientsWithApptAtTheSameTime = patients.filter(
      (item) =>
        item.isBookable === false &&
        item.isExistingApptWithinRequestedTime === true
    );

    if (patientsWithApptAtTheSameTime.length === 0) return null;

    return (
      <div className={styles['patient-list-section']}>
        <div className={styles['description-section']}>
          {renderDetailSection(PATIENT_LIST_TYPE.SAME_TIME_APPT)}
          {bookablePatients.length === 0 && renderNotYouSection()}
        </div>

        <div className={styles['card-container']}>
          {patientsWithApptAtTheSameTime.map((patient) => (
            <NewPatientCard key={patient.id} patient={patient} />
          ))}
        </div>
      </div>
    );
  };

  // Patients who are not allowed to book next appts
  const renderSinglePatientCard = () => {
    const tmpPatient = patients.find(
      (item) =>
        item.email === patient.email &&
        item.firstName === patient.firstName &&
        item.lastName === patient.lastName &&
        item.isBookable === false
    );

    const patientsWithApptAtTheSameTime = patients.find(
      (patient) => patient.isExistingApptWithinRequestedTime
    );

    const isInPast = tmpPatient?.appointment?.isInPast;

    if (patientsWithApptAtTheSameTime) {
      return (
        <>
          <div className={styles['detail-section']}>
            <div className={styles['title']}>
              <div>
                Looks like this patient has another appointment at the same time
              </div>
              <div className={styles['title-select']}>
                Please go back and change the time of this booking to schedule
                another appointment for any of the users below.
              </div>
            </div>
          </div>
          <NewPatientCard patient={patientsWithApptAtTheSameTime!} />
        </>
      );
    }

    return (
      <>
        <div className={styles['detail-section']}>
          <div className={styles['title']}>
            {isInPast ? (
              <div>
                Looks like this patient already has an incomplete appointment
              </div>
            ) : (
              <div>
                Looks like this patient already has a future appointment
              </div>
            )}
            <div className={styles['title-select']}>
              Please contact {bookingSummaryInfo.clinicName} at&nbsp;
              <b>
                {formatFullPhoneNumber(bookingSummaryInfo.clinicPhoneNumber)}
              </b>{' '}
              or&nbsp;
              <b>{bookingSummaryInfo.clinicEmail}</b> to schedule another
              appointment.
            </div>
          </div>
        </div>
        <NewPatientCard patient={tmpPatient!} />
      </>
    );
  };

  const handleOnDone = () => {
    window.location.reload();
  };

  const isDoneButton = useMemo(() => {
    if (!patient.id && patient.email) {
      // Patient is from New Patient form
      const existingPatientInList = patients.find(
        (item) =>
          item.email === patient.email &&
          item.firstName === patient.firstName &&
          item.lastName === patient.lastName
      );

      if (existingPatientInList) {
        return existingPatientInList.isBookable === false;
      }

      return false;
    }

    const bookablePatients = patients.filter(
      (item) => item.isBookable === true
    );

    return bookablePatients.length === 0;
  }, [patient, patients]);

  return (
    <div className={containerClassName}>
      {patient.email ? (
        <div className={styles['card-container']}>
          <div className={styles['description-section']}>
            {renderNotYouSection()}
            {renderDetailSection(PATIENT_LIST_TYPE.NORMAL)}
          </div>

          {isDoneButton ? (
            renderSinglePatientCard()
          ) : (
            <PatientCard patient={patient} chosenPatientId={chosenPatientId} />
          )}
        </div>
      ) : (
        <>
          {renderPatients()}
          {renderPatientsHasFutureApptInFuture()}
          {renderPatientsHasIncompleteApptInPast()}
          {renderPatientWithApptAtTheSameTime()}
        </>
      )}
      <NavigationBtnControl
        nextBtnTitle={isDoneButton ? 'Done' : 'Next'}
        onBack={handleOnBackClick}
        onNext={isDoneButton ? handleOnDone : handleOnSubmit}
      />
    </div>
  );
};

export default Review;

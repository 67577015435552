import { FC, useState } from 'react';
import cx from 'classnames';
import styles from '../Step3.module.scss';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';
import PatientCard from '../PatientCard/PatientCard';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import NavigationBtnControl from 'components/BookingForm/NavigationBtnControl/NavigationBtnControl';
import {
  popStep,
  replaceStep,
  STEPS,
  updateIsDone,
  updateIsKickedFromStep3,
  updateStep3,
} from 'redux/bookingFormSlice';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import { ChairOptimization } from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import { formatDobForBE } from 'components/BookingForm/utils/helperFunction';
import { AUTH_SYS_TYPE } from 'utils/constants/common';
import { signUpSocialThunk, signUpThunk } from 'redux/authSlice';
import { useLocation, useParams } from 'react-router-dom';
import {
  convertTimeHHmmss,
  MINUTE_OF_A_BLOCK,
} from 'utils/convertMinutesIntoHoursOfDay';
import { loadOtpToken } from 'utils/storage';
import { createBookingAPI } from 'services/APIs';
import moment from 'moment';
import { ROUTES } from 'routes/config';
import { batch } from 'react-redux';
import { ERROR_CODE } from 'components/DentalMap/utils/constant';
import { updatePractitionerExamCleaningServiceId } from 'redux/practitionerDetailsSlice';
import { updateExamCleaningServiceId } from 'redux/clinicDetailsSlice';
import { renderToast } from 'components/CustomToast/CustomToast';
import forwardEvent, { EVENT } from 'utils/tracking/forwardEventTracking';
import { scheduleTracking } from 'utils/tracking/metaEventTracking';
import sendTracking from 'utils/tracking/forwardTiktokOrGtagTracking';
interface NoteProps {
  onClose: () => void;
  clinicId: string;
  practitionerId: string;
  date: string;
  serviceId: string;
  operatoryId?: string;
  timeBlock: number;
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
  isPreferredDoctor?: boolean;
}

const Note: FC<NoteProps> = ({
  onClose,
  clinicId,
  practitionerId,
  date,
  timeBlock,
  operatoryId,
  serviceId,
  dentist,
  chairOptimizations,
  isPreferredDoctor,
}) => {
  const isLoadedInsideIframe = useIsLoadedInsideIframe();
  const [isLoading, setIsLoading] = useState(false);

  const {
    bookingFormSlice: {
      step1,
      step2: { signUp },
      step3: { patientInfo },
      isKickedFromStep3,
    },
    authSlice: { isLoggedIn, sysType },
    clinicDetailsSlice,
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const params = useParams<{
    practitionerSlugName?: string;
  }>();

  const isPractitionerPage = params.practitionerSlugName;

  const { search, pathname } = useLocation();

  const searchParams = new URLSearchParams(search);

  const patient = patientInfo!;

  const isCharacterExceed = patient.note.length! > 255;

  const containerClassName = cx({
    [styles['container']]: true,
    [styles['container-iframe']]: isLoadedInsideIframe,
  });

  const noteLengthClassName = cx({
    [styles['note-length']]: true,
    [styles['note-length-error']]: isCharacterExceed,
  });

  const textareaClassName = cx({
    [styles['textarea']]: true,
    [styles['textarea-error']]: isCharacterExceed,
  });

  const onNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      updateStep3({ patientInfo: { ...patientInfo!, note: e.target.value } })
    );
  };

  const createUser = async () => {
    const isSignUp = signUp.email;

    if (!isSignUp) {
      return;
    }

    const user = {
      email: patient.email,
      firstName: patient.firstName,
      lastName: patient.lastName,
      dob: formatDobForBE(patient.dob),
      phoneNumber: patient.phoneNumber,
    };

    if (sysType !== AUTH_SYS_TYPE.PASSWORD) {
      await dispatch(signUpSocialThunk(user));
      return;
    }

    const data = await dispatch(
      signUpThunk({
        ...user,
        password: signUp.password,
        sysType: AUTH_SYS_TYPE.PASSWORD,
      })
    );

    return (data?.payload as any).accessToken;
  };

  const createBooking = async (
    patientInfo: {
      id?: string;
      firstName: string;
      lastName: string;
      dob: string;
      email: string;
      phoneNumber: string;
      isNewPatient: boolean;
    },
    tmpAccessToken?: string
  ) => {
    const { id, firstName, lastName, email, phoneNumber, dob } = patientInfo;

    if (!operatoryId) {
      throw new Error('Missing Operatory Id');
    }

    const code = searchParams.get('code');

    const payload = {
      isClinicBooking: false,
      appointmentDate: moment(date).format('YYYY-MM-DD'),
      clinicId,
      patients: [
        {
          id,
          firstName,
          lastName,
          dob,
          email,
          phoneNumber,
          operatoryId: operatoryId || '',
          serviceId: serviceId,
          doctorId: practitionerId,
          startTime: convertTimeHHmmss(timeBlock * MINUTE_OF_A_BLOCK),
          useInsurance: false,
          dentist,
          patientNote: patient.note,
        },
      ],
      isSignedIn: Boolean(isLoggedIn),
      isPreferredDoctor,
      ...(code && { code }),
      ...(chairOptimizations && { chairOptimizations }),
    };
    const data = await createBookingAPI(payload, {
      otpToken: loadOtpToken(step1.phoneNumber),
      tmpAccessToken,
    });
    return data;
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const tmpAccessToken = await createUser();
      await createBooking(patient, tmpAccessToken);
      if (pathname !== ROUTES.RESULT_PAGE) {
        scheduleTracking(clinicDetailsSlice.clinicData.metaPixelCode);
        sendTracking(clinicDetailsSlice.clinicData.customScript);
        forwardEvent({
          googleTagManagerId: clinicDetailsSlice.clinicData.googleTagManagerId,
          isLoadedInsideIframe,
          website: clinicDetailsSlice.clinicData.website,
          payload: { event: EVENT.FORM_SUBMITTED },
        });
        forwardEvent({
          googleTagManagerId: clinicDetailsSlice.clinicData.googleTagManagerId,
          isLoadedInsideIframe,
          website: clinicDetailsSlice.clinicData.website,
          payload: { event: EVENT.FINAL_SLOT_SELECTED },
        });
      }
      setIsLoading(false);
      dispatch(updateIsDone());
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error === 'Unauthorized') {
        return batch(() => {
          dispatch(replaceStep(STEPS.VERIFICATION));
        });
      }
      if (error?.response?.status === 400) {
        message = error.response.data.message;
      }
      if (
        error?.response?.status === 400 &&
        error?.response?.data.code === ERROR_CODE.KICK_BACK
      ) {
        const newServiceId = error?.response.data.serviceId;
        batch(() => {
          dispatch(updateIsKickedFromStep3());
          isPractitionerPage
            ? dispatch(updatePractitionerExamCleaningServiceId(newServiceId))
            : dispatch(updateExamCleaningServiceId(newServiceId));
          renderToast({
            type: 'warning',
            message:
              'Unfortunately, there are no available slots matching your criteria. Please select a different time slot.',
          });
        });
        onClose();
        return;
      }
      renderToast({ message });
      setIsLoading(false);
    }
  };

  const onBackClick = () => {
    if (isKickedFromStep3) {
      return onClose();
    }
    dispatch(popStep());
  };

  return (
    <div className={containerClassName}>
      <div className={styles['card-container']}>
        <h3>Anything else we should know?</h3>
        <textarea
          className={textareaClassName}
          placeholder="Ie. area of concern/pain, insurance info, requested days/times to come sooner if opening available, etc."
          value={patientInfo?.note ?? ''}
          onChange={onNoteChange}
        />
        <p className={noteLengthClassName}>
          {isCharacterExceed && <ErrorIcon />}
          <span>{patientInfo?.note.length ?? 0}/255 characters</span>
        </p>
        <h3>Patient Info</h3>
        <PatientCard
          patient={{
            id: '',
            dob: patientInfo!.dob,
            email: patientInfo!.email,
            firstName: patientInfo!.firstName,
            lastName: patientInfo!.lastName,
            phoneNumber: patientInfo!.phoneNumber,
          }}
          chosenPatientId={''}
        />
      </div>
      <NavigationBtnControl
        data-firstin-submit={true}
        onNext={onSubmit}
        onBack={onBackClick}
        nextBtnTitle="Submit"
        isLoading={isLoading}
        isDisabledNextBtn={isCharacterExceed}
      />
    </div>
  );
};

export default Note;

import { FC, useCallback, useRef } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import {
  popStep,
  pushStep,
  resetCached,
  resetStep2NewPatient,
  STEPS,
  updateCached,
  updateStep3,
} from 'redux/bookingFormSlice';
import { getPatientsAPI } from 'services/APIs';
import { formatDobForBE } from 'components/BookingForm/utils/helperFunction';
import { loadOtpToken, saveOtpToken } from 'utils/storage';
import VerificationInput from 'components/VerificationInput/VerificationInput';
import { renderToast } from 'components/CustomToast/CustomToast';
import forwardEvent, { EVENT } from 'utils/tracking/forwardEventTracking';
import useIsLoadedInsideIframe from 'hooks/useIsLoadedInsideIframe';

interface VerificationProps {
  clinicId: string;
  serviceId: string;
  appointmentDate: string;
  startTime: string;
}

const Verification: FC<VerificationProps> = ({
  clinicId,
  serviceId,
  appointmentDate,
  startTime,
}) => {
  const {
    bookingFormSlice: {
      step1: { dob: dobStep1, phoneNumber: phoneNumberStep1 },
      step2: { signUp },
      step3: { patientInfo },
      pageStack,
    },
    clinicDetailsSlice: {
      clinicData: { googleTagManagerId, website },
    },
  } = useAppSelector((state) => state);

  const isLoadedInsideIframe = useIsLoadedInsideIframe();

  const previousPage = pageStack[pageStack.length - 2];

  const dob = previousPage === STEPS.SIGN_UP ? signUp.dob : dobStep1;

  const phoneNumber =
    previousPage === STEPS.SIGN_UP ? signUp.phoneNumber : phoneNumberStep1;

  const inputRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const handleOnBackClick = () => {
    batch(() => {
      dispatch(resetCached());
      dispatch(popStep());
    });
  };

  const handleCheckOtpSuccessful = async (otpAccessToken: string) => {
    saveOtpToken(otpAccessToken, phoneNumber);
    await handleGetPatients();
    dispatch(updateCached({ phoneNumber, dob }));
    forwardEvent({
      googleTagManagerId,
      isLoadedInsideIframe,
      website,
      payload: { event: EVENT.NUMBER_CONFIRMED },
    });
  };

  const handleGetPatients = async () => {
    if (previousPage === STEPS.SIGN_UP) {
      return batch(() => {
        dispatch(pushStep(STEPS.REVIEW));
        dispatch(
          updateCached({ phoneNumber: signUp.phoneNumber, dob: signUp.dob })
        );
      });
    }

    try {
      const formatDob = formatDobForBE(dob);

      const patients = await getPatientsAPI(
        {
          clinicId,
          serviceId,
          dob: formatDob,
          phoneNumber,
          appointmentDate,
          startTime,
        },
        {
          otpToken: loadOtpToken(phoneNumber),
        }
      );

      let nextStep = patients.length === 0 ? STEPS.NEW_PATIENT : STEPS.REVIEW;

      if (patientInfo?.email) {
        nextStep = STEPS.NOTE;
      }

      batch(() => {
        dispatch(resetStep2NewPatient());
        if (patients.length > 0) {
          dispatch(updateStep3({ patients }));
        }
        dispatch(pushStep(nextStep));
      });
    } catch (error) {
      displayErrorToast('Something went wrong. Please try again later');
    }
  };

  const displayErrorToast = useCallback((message: string) => {
    if (!inputRef.current) return;
    renderToast({ message });
  }, []);

  return (
    <VerificationInput
      onBackClick={handleOnBackClick}
      phoneNumber={phoneNumber}
      onCheckedOtpSuccessful={handleCheckOtpSuccessful}
    />
  );
};

export default Verification;

import { ChairOptimization } from 'pages/ClinicDetailsPage/ClinicDetailsPage';
import { DentistInfo } from 'pages/UpdatedSearchResultPage/utils';
import { FC } from 'react';
import { STEPS } from 'redux/bookingFormSlice';
import Note from './Note/Note';
import Review from './Review/Review';

interface Step3Props {
  onClose: () => void;
  clinicId: string;
  practitionerId: string;
  date: string;
  serviceId: string;
  operatoryId?: string;
  timeBlock: number;
  bookingSummaryInfo: {
    avatar: string;
    title: string;
    serviceTitle: string;
    clinicName: string;
    clinicAddress: string;
    clinicEmail: string;
    clinicPhoneNumber: string;
  };
  dentist: DentistInfo;
  chairOptimizations?: ChairOptimization[];
  isPreferredDoctor?: boolean;
  page: string;
}

const { REVIEW, NOTE } = STEPS;

const Step3: FC<Step3Props> = ({ page, bookingSummaryInfo, ...props }) => {
  return (
    <>
      {page === REVIEW && (
        <Review
          onClose={props.onClose}
          bookingSummaryInfo={bookingSummaryInfo}
        />
      )}
      {page === NOTE && <Note {...props} />}
    </>
  );
};

export default Step3;

import trackGoogle from './googleEventTracking';
import trackTiktok from './tiktokEventTracking';

const sendTracking = (customScript?: string | null) => {
  if (!customScript) return;

  const isGoogleAds = customScript.includes('gtag');

  const isTiktokTrack =
    customScript.includes('tiktok') &&
    customScript.includes('ttq.identify') &&
    customScript.includes('ttq.track');

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = customScript;

  const scriptElements = tempDiv.querySelectorAll('script');

  const isScriptLoaded =
    (window as any).dataLayer && (window as any).dataLayer.length > 0;

  if (!isScriptLoaded) {
    scriptElements.forEach((script) => {
      // SCRIPT DOES NOT RUN WHEN APPEND ORIGINAL SCRIPT
      const updatedScript = document.createElement('script');

      if (script.src) {
        updatedScript.src = script.src;
      } else {
        updatedScript.innerHTML = script.innerHTML;
      }

      document.head.appendChild(updatedScript);
    });
  }

  if (isGoogleAds) {
    trackGoogle();
  }

  if (isTiktokTrack) {
    trackTiktok(customScript);
  }
};

export default sendTracking;
